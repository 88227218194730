<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <!-- title -->
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">汇总管理</h5>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <!-- <a-button size="small" @click="chattest">测试chatgpt</a-button> -->
              </a-col>
            </a-row>
          </template>
          <!--  -->
          <a-radio-group v-model="tagSelect" :default-value="tagSelect" size="small" style="padding-left: 24px;padding-bottom: 20px;">
            <a-radio-button :value="item" v-for="(item,index) in tag" :key="index">
              {{ item }}时
            </a-radio-button>
          </a-radio-group>
          <!--  -->
          <a-card v-for="(item, index) in sumData[tagSelect]" :key="index" :title="`${item.classifyName} - ${item.createTime}时`"
            :headStyle="{ color: '#1890FF' }" style="margin-bottom: 20px;">
            <a slot="extra" @click="getDetails(item)">汇总明细</a>
            <p style="white-space: pre-wrap;">{{ item.content }}</p>
          </a-card>
        </a-card>
      </a-col>
    </a-row>
    <a-modal width="1000px" :visible="visible.detail.show" title="汇总详情" @cancel="visible.detail.show = false"
      :footer="null">
      <a-table :columns="table1Columns" :data-source="detailsList" :pagination="false" :rowKey="record => record.id">
        <template slot="userName" slot-scope="row">
          <a slot="link" :href="`https://twitter.com/${row.user}`" target="_blank">{{ row.userName }}</a>
        </template>
        <template slot="link" slot-scope="row">
          <a slot="link" :href="`https://twitter.com/${row.user}/status/${row.link}`" target="_blank">查看</a>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>

const table1Columns = [
  {
    title: 'tw用户',
    scopedSlots: { customRender: 'userName' },
    width: 150
  },
  {
    title: '内容',
    dataIndex: 'content',
    key: "content"
  },
  {
    title: '链接',
    key: 'link',
    scopedSlots: { customRender: 'link' },
    width: 80
  }
];
const formattedDate = (dateString, format) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  if (format == 'yyyy-mm-dd h:m:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else if (format == 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`;
  } else if (format == 'yyyy-mm-dd h') {
    return `${year}-${month}-${day} ${hours}`;
  }
}
// const formattedDate1 = (dateString) => {
//   const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
//   const day = date.getDate().toString().padStart(2, '0');
//   return `${year}-${month}-${day}`;
// }
import { getSummaryList, getSumDetailsList, getClassifyList } from "@/api/service.js";
import axios from "axios";
export default ({
  components: {
  },
  data() {
    return {
      visible: {
        detail: {
          show: false,
        }
      },
      tag: [],
      tagSelect: "",
      sumData:{},
      list: [],
      detailsList: [],
      table1Columns: table1Columns,
      categoryOrderMap: {}
    }
  },
  async mounted() {
    let categoryData = await getClassifyList().then();
    categoryData = categoryData.data.data;
    let summaryData = await getSummaryList({
      startTime: formattedDate((new Date().getTime() - 1000 * 60 * 60 * 24),'yyyy-mm-dd'),
      // startTime: '2024-01-20',
      endTime: formattedDate(new Date(), 'yyyy-mm-dd')
    })
    summaryData = summaryData.data.data;
    // 按时间 将数据分区
    let timeSummaryData = {}
    summaryData.forEach(item => {
      let date = formattedDate(item.createTime, 'yyyy-mm-dd h');
      if (!timeSummaryData[date]) {
        timeSummaryData[date] = []
      }
      timeSummaryData[date].push(item);
    })
    // 根据时间生成标签
    // 暂无


    // 构建分类排序映射表
    const categoryOrderMap = {};
    categoryData.forEach(category => {
      category.children.forEach(child => {
        const classifyName = category.name + '-' + child.name;
        categoryOrderMap[classifyName] = `${category.id}.${child.id}`;
      });
    });

    const groupedByDate = summaryData.reduce((acc, item) => {
      item.createTime = formattedDate(item.createTime, 'yyyy-mm-dd h');
      // item.time = formattedDate(item.time,'yyyy-mm-dd');
      // let date = formattedDate(item.createTime,'yyyy-mm-dd');
      if (!acc[item.createTime]) {
        acc[item.createTime] = [];
      }
      acc[item.createTime].push(item);
      return acc;
    }, {});
    // let list = [];
    // 标签
    Object.keys(groupedByDate).sort().reverse().forEach((date,index) => {
      this.tag.push(date);
      groupedByDate[date].sort((a, b) => {
        const orderA = categoryOrderMap[a.classifyName];
        const orderB = categoryOrderMap[b.classifyName];
        return orderA.localeCompare(orderB);
      })
      // list  = [...list, ...groupedByDate[date]];
    });
    this.tagSelect = this.tag[0];
    this.sumData = groupedByDate;
    // console.log(list)
    // this.list = list;
  },
  methods: {
    tagChange(e){
      this.tagSelect = e.target.value;
    },
    createCategoryLookup(categories) {
      const lookup = {};
      categories.forEach(category => {
        category.children.forEach(child => {
          const fullName = `${category.name}-${child.name}`;
          lookup[fullName] = category.id * 100 + child.id; // 创建唯一标识符
        });
      });
      return lookup;
    },
    getDetails(item) {
      getSumDetailsList({
        id: item.id
      }).then(res => {
        this.detailsList = res.data.data;
        this.visible.detail.show = true;
      })
    }
  }
})

</script>

<style lang="scss"></style>